<template>
 <vehicule-form type-ajout="stock" v-if="vehicule" :disabled-fields="disabledFields" :initial-vehicule="vehicule"  @created="vehiculeCreated" />
</template>

<script>
 
import {
  BFormInput, BRow, BCol, BFormGroup
} from 'bootstrap-vue'
import VehiculeForm from "@/components/VehiculeForm";
 

export default {
  components: {
    VehiculeForm
 
  },
  methods : {
    vehiculeCreated(vehicule){
      this.$router.push({
        name : 'editer-vehicule',
        params : {
          id : vehicule.id
        }
      });
    }
  },
  data() {
    return {
      immatriculation : '',
      vehicule : false,
      disabledFields : []
    }
  },
  created() {
 
 
    this.disabledFields = ['concession', 'site'];

    if(this.$can('voir-stock-global', 'stock') || this.$can('voir-stock-concession', 'stock')){
      this.disabledFields = [];
    }
    if((this.$can('voir-stock-site', 'stock'))){
      this.disabledFields = ['concession'];
    }




    this.vehicule = {
      proprietaire : {},

      statut_vente : { id: 3, libelle: "Stock"},
      site : this.$store.state.initialConfigModule.initialState.userData.userData.site,
      zone : this.$store.state.initialConfigModule.initialState.userData.userData.site,
      concession : this.$store.state.initialConfigModule.initialState.userData.userData.concession,
      vendeur : {
        id : this.$store.state.initialConfigModule.initialState.userData.userData.id,
        full_name : this.$store.state.initialConfigModule.initialState.userData.userData.fullName,
      }
    };
  }
}
</script>
